* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
body {
  background: linear-gradient(#1323db, #cc6aa3) no-repeat center center fixed;
  background-size: cover;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
p {
  font-size: 1.5rem;
}

a {
  text-decoration: none !important;
  font-size: 1.5rem;
}
a.consent-link {
  text-decoration: underline !important;
  color: #0331fc !important;
  font-size: 14px;
}

a:active,
a:hover {
  color: black !important;
}
a.consent-link:hover,
a.consent-link:active {
  color: #0331fc !important;
}

*:focus {
  outline: none !important;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 2.1rem;
  }
  h2 {
    font-size: 1.7rem;
  }
  p {
    font-size: 1.2rem;
  }

  a {
    text-decoration: none !important;
    font-size: 1.2rem;
  }
}

.navbar-container {
  color: white;
  display: flex;
  flex-wrap: wrap;
}

.navbar-container-second-back {
  background: rgb(0, 0, 0);

  background: linear-gradient(355deg, #1323db 10%, #cc6aa361 50%, #fe9f5d 75%);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-container a {
  color: white;
  background: inherit;
  font-size: 16px;
  line-height: 20px;
  margin-right: 80px;
  font-family: Helvetica;

  font-style: normal;
  font-weight: lighter;
  padding: 0;
  text-decoration: none;
}

.navbar-container a:hover {
  text-decoration: underline !important;
}

.header-nav {
  background: rgb(28, 29, 141);

  width: 100vw;
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.nav-container div {
  display: flex;
  flex-wrap: wrap;
}

.nav-buttons {
  flex-wrap: wrap;
}

.nav-buttons .nav-menu {
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  padding: 20px 0;
}

.nav-buttons .nav-menu a {
  font-size: 20px;
  font-family: Helvetica;
  margin-right: 40px;
  text-transform: uppercase;
  padding: 10px 0 5px;
}

.nav-button {
  font-size: 20px;
  font-family: Helvetica;
  margin-right: 40px;
  text-transform: uppercase;
  padding: 10px 0 5px;
  border: none;
  background: transparent;
  color: white;
  font-weight: lighter;
}

.nav-assets {
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.nav-assets img {
  width: 4vw;
}

.nav-assets p {
  font-size: 64px;
  line-height: 1;
  font-weight: bold;
  font-style: italic !important;
  font-family: Helvetica;
}

.header-nav .red-gine-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-nav .red-gine-container p {
  margin: 0;
  font-size: 1rem;
  color: white;
}

.buscador {
  margin-left: auto;
}

.buscador input {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: lighter;
  line-height: 24px;
  background-color: transparent;
  padding: 4px 0;
  max-width: 400px;
  color: #ffffff;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: Helvetica;

  background-image: url(/static/media/lupe.027c23bf.svg);

  background-repeat: no-repeat;
  background-position: 95%;
}
.buscador input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 0.5; /* Firefox */
  font-style: italic;
}
.buscador input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 0.5; /* Firefox */
  font-style: italic;
}
@media screen and (max-width: 770px) {
  /* .navbar-container {
    height: 23vh;
  } */
  .nav-buttons .nav-menu {
    width: 100%;
  }

  .nav-assets img {
    width: 10vw;
  }
  .nav-assets p {
    font-size: 8vw;
  }

  .header-nav {
    height: 6vh;
  }
  /* .nav-buttons .nav-menu a {
    font-size: 1.4rem;
  }

  .header-nav .red-gine-container p {
    font-size: 0.7rem;
  } */
}

@media screen and (max-width: 800px) and (max-height: 420px) {
  .nav-assets p {
    font-size: 10vh !important;
  }
  .nav-assets img {
    width: 12vh !important;
  }
}

@media screen and (max-width: 320px) {
  /* .nav-buttons .nav-menu a {
    font-size: 0.8rem;
  } */
  /* .nav-buttons .nav-menu a {
    font-size: 0.9rem;
  } */
  /* .nav-buttons .nav-menu div:first-child a {
    font-size: 0.9rem;
    margin: 0 0 0 10px;
  } */
}

.Footer {
  background: url(/static/media/Footer_0.5569f96f.webp);
  color: #ffffff;
  padding: 60px 0;
  flex: 1 1;
  background-repeat: no-repeat;
  background-size: cover;
}

.Footer .red-gine-container {
  display: flex;
  flex-direction: column;
}

.links {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  max-width: 41.66667%;
}

.links a {
  cursor: pointer;

  color: #ffffff;
}
.links a:hover {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff !important;
  font-weight: 600;
  color: white;
}
.links a:last-child {
  margin-bottom: 0;
}

.right {
  font-family: Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
}
.right p {
  margin-bottom: 10px;
}

.right p:last-child {
  margin-bottom: 0;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .Footer .red-gine-container {
    display: flex;
    flex-direction: row;

    width: 100% !important;
    max-width: 1900px !important;

    padding: 0 100px;
  }
  .links {
    flex: 1 0;
    margin-bottom: 0px;
  }
  .right {
    flex: 1 0;
    max-width: 50%;
  }
}

@media (min-width: 1200px) {
}

.hero-container {
  margin-bottom: 2rem;
}

.dropdowns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.img-copy {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 10rem;

  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-image {
  position: relative;
}

.line {
  width: 5rem;
  background: white;
  border: 2px solid white;
}

.busco {
  margin: 0.5rem 0;

  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
}

.img-copy {
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.orange-container {
  padding: 1rem;
  background: #fc8902;
  background: #fc8902;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.body h2 {
  font-style: italic;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
}

.body p {
  font-size: 20px;
}

.body img {
  width: 46px;
}

.body h3 {
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
}

.body p.small {
  font-size: 12px;
  font-weight: bold;
}

.dropdowns button {
  background: #0331fc;
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  text-transform: uppercase;
  width: 16rem;
}

.dropdowns .btn-primary {
  font-size: 1.5rem !important;
  padding: 1rem 2rem;
}

.dropdown-toggle::after {
  position: absolute;
  top: 2rem !important;
  margin: auto;
}

.actions {
  margin: 1rem 0;
}

.credenciales {
  display: flex;
  align-items: center;
  justify-content: center;
}

.credenciales p {
  margin-right: 1rem;
}

@media (min-width: 576px) {
  .busco {
    font-size: 46px;
    margin: 1rem 0;
  }
  .copy {
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .hero-container {
    display: flex;
  }

  .hero-image {
    flex: 1 0 50%;
  }

  .hero-image img {
    object-fit: contain;
    max-width: 100%;
  }

  .orange-container {
    flex: 1 0 50%;
  }

  .busco {
    font-size: 30px;
  }

  .copy {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .busco {
    font-size: 46px;
    margin: 2rem 0;
  }

  .copy {
    font-size: 28px;
  }

  .line {
    width: 10rem;
  }

  .hero-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) {
}

.center-content {
  height: 100%;
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}
.container {
  max-width: 59vw !important;
}

.center-content-general {
  height: 50vh;
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}

.vertical_adjust_30 {
  height: 70%;
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}

.formCentral {
  width: 90%;
  color: white;
  margin-bottom: 10px;
}
.formCentral h2,
p {
  color: white;
}
.formCentral h2 {
  font-style: italic;
  font-weight: bold;
  font-size: 3vw;
}
.formCentral p {
  font-size: 2vw;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_bold {
  font-weight: bold;
}

.left_bottom {
  height: 90%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 5vw;
}

.formCentral .col-lg-6 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
#foto_mujer,
#foto_cuadro_naranja {
  width: 100% !important;
  height: auto;
}

.back_formCentral {
  position: relative;
}
.over_FormCentral {
  position: absolute;
  width: 100%;
  height: 100%;
}

.formCentral_button {
  background-color: #ff00a4;
  border: none;
  color: white;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  font-style: italic;
  font-size: 14px !important;
  font-weight: bold;
  cursor: pointer;
}

.formCentral_clinicas {
  background-color: #bf628a;
  border: none;
  color: white;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  font-style: italic;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  min-width: 100px;
}

.admin_input {
  color: #fff;
  background-color: #1a30fc;
  border-color: #1a30fc;
  padding: 3px 15px;
  font-weight: bold;
  width: 75%;
  margin: 10px 0px;
  font-size: 1vw !important;
  display: block;
}

.admin_input::-webkit-input-placeholder {
  color: #fff;
  font-style: italic;
}

.admin_input::placeholder {
  color: #fff;
  font-style: italic;
}
.formCentral_select .btn-primary:disabled {
  background-color: #1a30fc !important;
}

.form_login {
  background: #fc8902;
  color: white;
  height: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wildcard_container {
  margin: 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .formCentral_select .btn-primary {
    width: 150px;
  }
  .container {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .formCentral h2 {
    font-size: 10vw;
  }
  .text_left {
    font-size: 2rem;
  }

  .formCentral_select .btn-primary {
    width: 100% !important;
    max-width: 100%;
    font-size: 12px !important;
  }
  .dropdown-menu.show {
    overflow: scroll;
    max-height: 20vh !important;
  }
}

@media screen and (max-width: 320px) {
  .over_FormCentral .center-content {
    padding: 0 10px !important;
  }
}

@media screen and (max-width: 800px) and (max-height: 420px) {
}

.clinic-prev {
  margin: 5px auto;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid gray;
}

.clinic-icon img {
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}

.clinic-prev div {
  display: flex;
  justify-content: center;
}

.clinic-prev div p {
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}

.clinic-prev div h2 {
  text-align: left;
  color: #1c2afc;
  font-weight: bold;
  font-size: 2.5rem;
}

.prev-control {
  margin: 5px 0;
}
.opacity {
  opacity: 0.5;
}

.loader-div {
  height: 15rem;
}

.prev-doctor {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 770px) {
  .clinic-prev {
    flex-direction: column;
    justify-content: center;
  }
  .clinic-prev div h2 {
    text-align: center;
    font-size: 2rem;
  }
  .clinic-prev div p {
    font-size: 1rem;
  }

  .clinic-prev div p,
  .clinic-prev div h2,
  .clinic-prev div h3 {
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
}

.clinicas-container {
  background: white;
  overflow: auto;
  flex: 1 1;
}

.header {
  margin: 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  width: 100%;

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  background: white;
}

.header div:first-child {
  display: flex;
  flex-direction: column;
  min-width: 10%;
}

.header-container {
  min-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .header-container img {
  margin: 0;
  margin: auto;
  height: 7vh;
}
.header .header-container h1 {
  margin: 10px;
  color: #0418fc;
  font-weight: bold;
  font-style: italic;
  font-size: 4vh;
}

.listOfClinics {
  overflow: scroll;
  overflow-x: hidden;
}

.genero .btn-primary,
.genero .btn-primary:disabled {
  background-color: #fa8825 !important;
}
.genero .btn-primary:hover,
.genero .show .btn-primary.dropdown-toggle,
.genero .btn-primary:focus {
  background-color: #fa8825 !important;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.especialidad .btn-primary,
.especialidad .btn-primary:disabled {
  background-color: #9d40a1 !important;
  padding-right: 30px;
}

.especialidad .btn-primary:hover,
.especialidad .show .btn-primary.dropdown-toggle,
.especialidad .btn-primary:focus {
  background-color: #9d40a1 !important;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.experiencia .btn-primary:disabled,
.experiencia .btn-primary {
  background-color: #1c2afc !important;
}
.experiencia .btn-primary:hover,
.experiencia .show .btn-primary.dropdown-toggle,
.experiencia .btn-primary:focus {
  background-color: #1c2afc !important;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.horario .btn-primary,
.horario .btn-primary:disabled {
  background-color: #cc6aa3 !important;
}

.horario .btn-primary:hover,
.horario .show .btn-primary.dropdown-toggle,
.horario .btn-primary:focus {
  background-color: #cc6aa3 !important;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.btn-primary {
  width: 100%;
  border: 0 !important;
  font-size: 1.5rem !important;
}

.dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 10px;
}

.start-clinics {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}
.start-clinics img {
  width: 10%;
}
.start-clinics h1 {
  color: #fa8825;
  width: 60%;
  text-align: center;
}

.__react_component_tooltip {
  width: 35vw !important;
}

.filter-doctor {
  padding: 5px 10px;
  font-size: 14px;
  margin: 4px 2px;
  border: 1px solid gray;
  border-radius: 5px;
}
.admin-actions-container .back-button {
  background-color: #9d40a1;
}

.admin-actions-container .back-button.logout {
  background-color: #1c2afc;
}

@media screen and (max-width: 770px) {
  .__react_component_tooltip {
    width: 50% !important;
  }
  .clinicas-container {
    background: white;
    height: auto !important;
  }
  .listOfClinics {
    height: auto !important;
    overflow: scroll;
    padding-right: 0px;
  }

  .header .header-container img {
    height: 6vh;
  }

  .header h1 {
    font-size: 3vh !important;
    text-align: center;
    width: 100%;
  }
  .btn-primary {
    font-size: 1rem !important;
  }

  .especialidades-item {
    width: 50% !important;
  }
}

@media screen and (max-width: 550px) {
  .especialidades-item {
    width: 100% !important;
  }
}

@media screen and (max-width: 320px) {
  .header div:first-child {
    flex-direction: column-reverse;
    min-width: 100%;
  }
  .back-button {
    width: 100%;
  }
  .searchContainer .dropdown {
    width: 100%;
    margin: 10px auto;
  }

  .admin-actions-container {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media screen and (max-width: 550px) {
  .admin-actions-container {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .filter-doctor {
    width: 100%;
  }
}

.clinic-card {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  padding: 2rem;
  align-items: center;
  width: 100%;
}
.clinic-card:not(:last-child) {
  border-bottom: 1px solid lightgray;
}
.clinic-card img {
  width: 67px;
  height: 67px;
  object-fit: contain;
  flex: 1 1;
}
.clinic-card div {
  flex: 2 1;
}

.clinic-card div p {
  color: black;
  font-size: 12px;
  margin-bottom: 4px;
}

.clinic-card h2 {
  color: #1c2afc;
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 0;
  flex: 2 1;
}

@media screen and (max-width: 770px) {
  .clinic-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .clinic-card div {
    flex: 1 1;
  }
  .clinic-card h2 {
    font-size: 1.5rem;
  }
}

/* canvas {
  width: 540px;
  height: 660px;
} */
.map-container {
  width: 540px !important;
  height: 660px !important;
  border-radius: 5px;
}
.mapboxgl-map {
  width: 100% !important;
}

/* Marker */

.marker {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.marker-clinic {
  background-image: url(/static/media/clinica.de209315.png);
}
.marker-Mujer {
  background-image: url(/static/media/doctora.4a4e69f4.png);
}
.marker-Hombre {
  background-image: url(/static/media/doctor.158d0529.png);
}
/* Circle */
.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: blue;
  cursor: pointer;
}

.circle:before,
.circle:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid blue;
  border-radius: 50%;
}

.circle:before {
  -webkit-animation: ripple 2s linear infinite;
          animation: ripple 2s linear infinite;
}

.circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
          animation: ripple 2s linear 1s infinite;
}

@media screen and (max-width: 770px) {
  .map-container {
    width: 100% !important;
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}

.clinicas-container {
  background: white;

  overflow: hidden;
  flex: 1 1;
}

.clinicas-container .red-gine-container {
  padding: 0 !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cli-container {
  margin-top: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropDown-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.listOfClinics-container {
  display: flex;
  height: 660px;
  overflow-y: hidden;
  border: 2px solid #ff8a01;
  flex-wrap: wrap;
}
.left-container {
  width: 45%;
}
.right-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 55%;
  align-items: center;
  overflow: scroll;
}
.fade-in-fast {
  margin-top: 3rem;
}

.dropDown-menu-item {
  flex: 1 1;
  width: 15%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.dropDown-menu-item:last-child {
  margin-right: 0rem !important;
}

.dropDown-menu-item:last-child {
  margin-right: 0;
}

.dropdown button {
  border-radius: 0 !important;
  padding: 1rem;
}

.start-clinics {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
}

.start-clinics h1 {
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
}

.start-clinics h2 {
  font-family: Helvetica Neue;
  font-style: italic;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #0331fc;
}

.start-clinics button {
  font-family: Helvetica Neue;
  font-weight: bold;
  font-size: 2rem;
  line-height: 39px;
  text-align: center;

  padding: 1rem;

  border: 0;
  background: #0331fc;
  color: white;
  margin-top: 3rem;
}

@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 770px) {
  .listOfClinics-container {
    height: auto;
  }
  .dropDown-menu {
    justify-content: space-between;
  }

  .has_muni {
    justify-content: flex-start;
  }

  .has_muni .dropDown-menu-item {
    flex: 1 0 30%;
    max-width: 30%;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .dropDown-menu-item {
    flex: 1 0 33%;
    max-width: 33%;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .left-container {
    width: 100%;
  }
  .right-container {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .dropDown-menu-item {
    flex: 1 0 45% !important;
    max-width: 49% !important;
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }

  .dropDown-menu-item:nth-child(odd) {
    margin-right: 0.5rem !important;
  }

  .dropDown-menu-item:last-child {
    margin-right: 0rem !important;
    margin-bottom: 0rem !important;
  }
}
@media screen and (max-width: 320px) {
}

.doctor-main {
  /* background: gray; */
  flex: 1 1;
  height: 98%;
}
.doctor-main .header {
  /* background: red; */
  justify-content: flex-start;
  padding: 5px;
  padding-left: 0;
}

.clinica-full {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;

  width: 80%;
  margin: 1rem 0;
  /* background: red; */
}

.clinica-info {
  margin-left: 10px;
}

.doctor-info,
.clinica-info {
  /* background: pink; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.clinica-full .doctor-info {
  position: relative;
  max-width: 50%;
}

.doctor-info .doctor-img {
  /* background: gray; */
  display: flex;
  flex-direction: column;
}

.loader-picture {
  min-height: 50%;
  position: relative;
  min-width: 25rem;
}

.doctor-info .doctor-img img {
  /* background: red; */
  margin: 5px 0;
  max-width: 100%;
  max-height: 37rem;
  border-radius: 20px;
  align-self: center;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.doctor-cursos {
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-cursos-container {
  display: flex;
  /* margin: 10px; */
  width: 40rem;
  justify-content: space-evenly;
}

.doctor-curso {
  flex: 0 0 30%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  /* background: white; */
}

.doctor-curso img {
  margin: 10px 0;
  width: 8rem;
}

.doctor-curso p {
  color: #0331fc;
  font-weight: bold;
}

.clinica-info {
  /* background: yellow; */
  align-items: flex-start;
  overflow: auto;
  flex: 1 1;
}

.doctor-credenciales {
  /* background: red; */
  width: 90%;
  margin: auto;
}

h2.doctor_title {
  color: #afafaf;
  font-size: 1.5rem;
}

h1.doctor_name {
  color: #1c2afc;
  font-size: 2.5rem;
  margin: 5px 0;
}

h3.doctor_specialty {
  font-size: 1rem;
  color: #1c2afc;
}

.clinica-body {
  /* background: pink; */
  max-width: 100%;
  width: 100%;
  flex: 1 1;
  overflow: auto;
}

.carousel .slide {
  min-width: 100%;
  background: transparent;
}

.consultury_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background: gray; */
  width: 90%;
  margin: auto;
  overflow: auto;
}

p.clinic_address {
  text-align: left;
  color: black;
  font-weight: bold;
  margin: 5px 0;
}

.imagenes-consultorio {
  display: flex;
  margin: 5px 0;
  width: 100%;
  max-height: 30rem;
}

.imagen-clinica {
  /* background: grey; */
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  position: relative;
  max-height: 100%;
  /* min-height: 10rem; */
}

.imagen-body-clinica {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: space-between;
  max-height: 19rem;
}

.imagen-clinica img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  border-radius: 15px !important;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  /* border: 1px solid black !important; */
  /* background: red; */
  margin: 5px;
}

.admin .imagen-clinica img {
  max-height: 80% !important;
  margin: 0px;
}

.imagen-clinica div {
  display: flex;
  flex-direction: column;
}

.horarios-container {
  display: flex;
  /* background: green; */
  width: 100%;
  flex-wrap: wrap;
}

.horary_box {
  background: #e5e5e5;
  margin: 3px;
  flex: 0 0 24%;
  border-radius: 10px;
  padding: 3px;
}

.horary_box p {
  font-family: Helvetica Neue;
  font-style: italic;
  color: #afafaf;
  margin: 0;
  padding: 0;
}
.horary_box p:nth-child(2) {
  font-size: 11px;
}
.horary_box img {
  margin: 5px 0 !important;
  height: 4rem !important;
  width: auto !important;
}

.isToday {
  background: #fc8902;
}

.isToday p {
  color: white;
}

.contact_row {
  display: flex;
  /* background: red; */
  margin-right: 10px;

  flex-wrap: wrap;
}

.clinica-footer {
  display: flex;
  /* background: pink; */
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 5px 0;
}

.container_contact {
  display: flex;
  align-items: center;
  padding: 5px;
  /* background: pink; */
}

.text_contact {
  display: flex;
  align-items: center;
  padding: 5px;
}

img.icon_contact {
  width: 4rem !important;
  margin: 0px 10px !important;
}

.tel-body {
  /* background: green; */
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tel-body a,
.tel-body p {
  font-size: 12px;
  text-decoration: none;
  color: black;
  margin: 0;
  text-align: left;
}

.text_contact a {
  font-size: 12px;
  text-decoration: none;
  color: black;
}

.control-next:before {
  content: url(/static/media/Arrow.58f923f7.svg) !important;
  border-top: 0 solid transparent !important;
  border-bottom: 0 solid !important;
  border-left: 0px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
  content: url(/static/media/Arrow.58f923f7.svg) !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  border-right: 0px solid #fff !important;
}

.start-clinics.no_doctors.fade-in-slow {
  margin: 0 auto;
}

.start-clinics.no_doctors.fade-in-slow img {
  width: 11rem;
}

.clinic-btns {
  display: flex;
  margin: 5px 0;
  flex: 1 1;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  /* background: red; */
}

.delete-overlay {
  background: rgba(1, 1, 1, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
}

.delete-container {
  background: white;
  width: 35%;
  height: 35%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.delete-container img {
  position: absolute;
  width: 2rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.delete-container h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #afafaf;
}

.delete-btns {
  /* background: red; */
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0;
}

.delete-btns .back-button {
  width: 30%;
  font-size: 1.5rem;
}

.delete-spinner {
  width: 100%;
  height: 3rem;
  position: relative;
  bottom: -3rem;
}

.carousel.carousel-slider,
.carousel-root {
  height: 100% !important;
  overflow: auto !important;
}

.carousel .control-dots {
  position: relative !important;
  top: 0;
}

li.dot {
  background: gray !important;
}

li.dot.selected {
  background: #fc8902 !important;
}

.carousel.carousel-slider .control-arrow {
  padding: 0px !important;
}

@media (max-width: 1750px) {
  .clinica-full {
    width: 85%;
  }

  .horary_box {
    margin: 3px;
    flex: 0 0 23%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
  .clinica-full {
    width: 100% !important;
  }

  .carousel.carousel-slider .control-arrow {
    font-size: 10px !important;
    padding: 0px !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 850px) {
  .imagenes-consultorio {
    flex-direction: column !important;
    margin: 5px 0;
  }

  .imagen-clinica {
    margin: 0;
  }

  .imagenes-consultorio {
    display: flex;
    margin: 5px 0;
    /* background: red; */
    width: 100%;
    height: auto;
    max-height: 100%;
  }
  .imagen-body-clinica {
    max-height: 100%;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 768px) {
  .doctor-info {
    min-width: 100%;
    max-width: 100%;
  }

  .clinica-info {
    overflow: hidden !important;
    flex: none;
    width: 100%;
  }

  .clinica-full {
    overflow: hidden !important;
    height: auto;
  }

  .header-admin {
    z-index: 10000000;
  }

  .tel-body a,
  .tel-body p,
  .text_contact a {
    font-size: 15px;
  }


  .text_contact a:hover  {
     color: red !important;
  }

  img.icon_contact {
    width: 3rem !important;
  }
  .contact_row {
    margin: 0;
  }

  .container_contact,
  .text_contact {
    padding: 10px 0;
  }

  .doctor-curso img {
    margin: 10px 0;
    width: 6rem;
  }

  .doctor-cursos-container {
    width: 80%;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .carousel .control-dots {
    bottom: -10px !important;
  }

  .horary_box {
    flex: 1 0 23%;
  }
}

@media (max-width: 320px) {
  .doctor-cursos-container {
    width: 100%;
  }
}

.red-gine-container {
  width: 95%;
  margin: 0 auto;
  padding: 6px 0;

  max-width: 1140px !important;
}

.fade-in-slow {
  -webkit-animation: fadein 0.8s forwards ease-in;
          animation: fadein 0.8s forwards ease-in;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-fast {
  -webkit-animation: fadein 0.4s forwards ease-in;
          animation: fadein 0.4s forwards ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdown-item {
  font-size: 1rem !important;
  box-sizing: border-box !important;
  border-bottom: 1px dotted #fc8902 !important;
}

.dropdown-item:last-child {
  border-bottom: 0 !important;
}

.dropdown-menu.show {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 15vh !important;
  width: 100% !important;
}

.dropdown-toggle {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.orange-button,
.back-button,
.del-button {
  border: none;
  color: white;
  text-align: center;
  align-self: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  font-style: italic;
  border: 0;
  display: inline-block;
  margin: 3px;
  padding: 1rem;
  color: white;
  cursor: pointer;
}

.orange-button {
  background: #fc8902;
}

.back-button {
  background-color: #1c2afc;
  font-style: normal;
}

.del-button {
  background-color: red;
  font-style: normal;
}

.del-button:hover {
  text-decoration: underline;
}
.del-button:active {
  background: rgb(226, 69, 69);
}

a.DropdownCustom.dropdown-item {
  white-space: pre-line;
}

@media screen and (max-width: 1000px) {
  .red-gine-container {
    width: 90%;
  }
}
@media screen and (max-width: 770px) {
}

@media screen and (max-width: 420px) {
  .dropdown-menu.show {
    max-height: 30vh !important;
  }
}
@media screen and (max-width: 320px) {
}

ul,
li {
  list-style: none;
}

